import  './Benefits.css'



import { ReactComponent as GlobalIconSvg } from '../../assets/icons/global.svg';

function Benefits({title,children}){


    return (<div className="benfitsSection">

<p className='benfitsSection__title'>{title}</p>
<div  className="benefits">

    {  children.map(( benefit, index)=>{


const BenefitIcon=benefit.icon;
return ( <div className="benefitsCard">

<BenefitIcon className='benefitIcon' fill='white' />

<h3  className='benefitTitle'>{benefit.title}</h3>
<p className='benefitDescription'>{benefit.description}</p>
</div>)
    })}
   
   
</div>
    </div>);
}


export default Benefits