import './AppStorebutton.css'
import googleButton from '../../assets/googlebutton.png'
import appStoreButton from '../../assets/appstorebutton.png'


import {playStoreLink,appStoreLink} from './../../constants/linkscontants'
function AppStorebuttons(){


    return (
    < div  className='appButtons'>    
    
    
  <a href={playStoreLink}>  <img  src={googleButton}  alt='google play button'  className='appButton'></img></a>
   <a href={appStoreLink}><img  src={appStoreButton}  alt='App store button'  className='appButton'></img></a> 
      </ div>
      );


}


export default AppStorebuttons;