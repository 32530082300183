
import HomeMainSection from './HomeMainSection/HomeMainSection.jsx'
import PixlmeBenefits from './PixlmeBenefits/PixlmeBenefits.jsx'
import HomeGuide from './HomeGuide/HomeGuide.jsx'
import ShowCase from './HomeShowCase/HomeShowCase.jsx'
import Testimonials from '../../components/Testimonials'
import Features from './Features/Features.jsx'
import HomeContest from './HomeContest/index.js'
import StreamArt from '../../components/StreamArt/index.jsx'
import HomeBenefitsArts from './HomeBenefitsArts/index.js'
import PricingComponent from '../../components/Pricing/index.jsx'


function Home(){
    return (<div className="homepage">
        <HomeMainSection/>

        <PixlmeBenefits/>
        <HomeGuide/>
        <StreamArt/>
        <ShowCase/>
        <Testimonials user="artist"/>
        <Features/>
        <HomeContest/>
        {/* <HomeBenefitsArts/> */}
        <PricingComponent/>
   

    </div>);
}


export default Home;