import React, { useState } from "react";
import "./Header.css";
import logo from '../../assets/pixlme.webp';
import { FaChevronDown } from "react-icons/fa";
import { Link } from 'react-scroll';

import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);


  const[ isUserRoleOpen,setIsUserRoleOpen]= useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleUserRoleOpen = () => {
    setIsUserRoleOpen(!isUserRoleOpen);
  };

  return (
    <header className="header">
      <a href="/" className="logo">
        <img src={logo} alt="Logo" />
      </a>
      <nav className={`nav ${isOpen ? "open" : ""}`}>
        <a href="/">Home</a>
        <div className="navbar-items" onClick={toggleUserRoleOpen}>
         <span className="item"> How it works </span>  <FaChevronDown className="navbar-items-icon" />
          {isUserRoleOpen && (
            <div className={`dropdown-menu ${isUserRoleOpen ? "open" : ""}`}>
              <a href="/forConsumerPage" className="dropdown-item">For Consumer <CgChevronRight/> </a>
              <a href="/forArtistPage" className="dropdown-item">For Artist <CgChevronRight/></a>
            </div>
          )}
        </div>


<a href="/forConsumerPage" className="responsive-item">For Consumer</a>
<a href="/forArtistPage" className="responsive-item">For Artist</a>



       <Link  to="features" smooth={true} duration={1300}> <a>Features</a></Link> 
       <Link  to="artists" smooth={true} duration={1300}> <a>Artists</a></Link> 
       <Link  to="pricing" smooth={true} duration={1300}> <a>Pricing</a></Link> 
  


<div className="responsive-item"> <button className="sign-up">Sign up</button></div>
<div className="responsive-item"> <button className="login">Login</button></div>
   

        
        
      </nav>
      <div className="auth-buttons">
        <button className="sign-up">Sign up</button>
        <button className="login">Login</button>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </header>
  );
};

export default Header;
