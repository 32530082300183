import './PixlmeBenefits.css';
import { benefit1, benefit2, benefit3 } from '../../../assets/benefit/index';
import AppStorebuttons from '../../../components/AppStorebutton/index';

function PixlmeBenefits() {

    const benefits = [
        {
            image: benefit1,
            title: "For Artists",
            description: "Showcase your Art - Upload, categorize and share your work with a global audience. Gain visibility and potential revenue",
            backGroundColor: '#F4DAC2'
        },
        {
            image: benefit2,
            title: "For Consumers",
            description: "Discover and enjoy - Browse a diverse range of curated collections and personalize your viewing experience",
            backGroundColor: '#D5F4C2'
        },
        {
            image: benefit3,
            title: "Ease of use",
            description: "2-Click streaming - effortlessly stream art to your TV or display devices using the pixlme app",
            backGroundColor: '#C2F4DF'
        }
    ];

    return (
        <div className="pixlmeBenefits__section">
            <div className="pixlmeBenefits__title">Benefits of Pixlme</div>
            <ul id="cards" style={{ '--cards': benefits.length }}>
                {benefits.map((benefit, index) => (
                    <li key={index} className="card" id={`card${index}`} style={{ '--index': index + 1 }}>
                        <div className="pixlmeBenefits__card" style={{ backgroundColor: benefit.backGroundColor }}>
                            <div className='pixlmeBenefits__card__left'>
                                <p className='pixlmeBenefits__card__title'>{benefit.title}</p>
                                <p className='pixlmeBenefits__card__description'>{benefit.description}</p>
                                <p className='pixlmeBenefits__card__button'>Learn More</p>
                             <div className='pixlmeBenefits-appstore-buttons'><AppStorebuttons /></div>   
                            </div>
                            <img src={benefit.image}  alt={benefit.title} className='pixlmeBenefits__card__right' />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PixlmeBenefits;
