import React from 'react';
import './Footer.css';
import logo from '../../assets/pixlme.webp';
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';

function Footer() {
    const companyLinks = [
        { title: "About Us", url: '/' },
        { title: "Support", url: '/' },
   
    ];

    const quickLinks = [
        { title: "Login", url: '/' },
        { title: "Sign Up", url: '/' },
     
    ];

    const socialLinks = [
        { title: "Facebook", url: '/', icon: <FaFacebook /> },
        { title: "Instagram", url: '/', icon: <FaInstagram /> },
        
    ];

    return (
        <div className="footer-container">
            <div className="footer-main">
                <div className="footer-left">
                    <img src={logo} alt="logo" className="footer-logo" />
                    <div>
                        <p className="footer-detail-title">Contact</p>
                        <p className="footer-detail-description">support@pixlme.com</p>
                    </div>
                </div>
                <div className="footer-right">
                    <QuickLinks title="Our Company" links={companyLinks} />
                    <QuickLinks title="Quick Links" links={quickLinks} />
                    <QuickLinks title="Follow Us" links={socialLinks} />
                </div>
            </div>
            <div className="footer-terms">
                <div className="footer-terms-left">
                    <p className="footer-terms-description">
                        © 2024 PiXLMe. All Rights Reserved.
                    </p>
                </div>
                <div className="footer-terms-right">
                    <a href="/privacy-policy" className="footer-terms-link">Privacy Policy</a>
                    <a href="/terms-and-conditions" className="footer-terms-link">Terms and Conditions</a>
                    <a href="/license-agreement" className="footer-terms-link">License Agreement</a>
                </div>
            </div>
        </div>
    );
}

function QuickLinks({ title, links }) {
    return (
        <div className="quick-links">
            <p className="footer-detail-title">{title}</p>
            {links.map((link, index) => (
                <a key={index} href={link.url} className="footer-detail-description">
                    {link.icon && <span className="link-icon">{link.icon}</span>}
                    {link.title}
                </a>
            ))}
        </div>
    );
}

export default Footer;
