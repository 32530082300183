import './HomeMainSection.css'

import React  from 'react';

import  {homepage_frame_1,homepage_frame_2,homepage_frame_3,homepage_frame_4,homepage_frame_5,homepage_frame_6} from '../../../assets/homepage/index'

const ResponsiveHomeDisplayImages=()=>{


    return (
        <div className='responsiveHomeDisplayImages'>

<div className='responsiveHomeDisplayImages-left'>
    <img src={homepage_frame_3} alt='Home image 1' className='responsiveHomeDisplayImages-image1'/>
            <img  src={homepage_frame_1} alt='Home image 2' className='responsiveHomeDisplayImages-image2'/>
            <img src={homepage_frame_4}  alt='Home image 3' className='responsiveHomeDisplayImages-image3'/>
            </div>

<div className='responsiveHomeDisplayImages-right'>
    <img src={homepage_frame_6} alt='Home image 4' className='responsiveHomeDisplayImages-image4'/>
            <img src={homepage_frame_5}  alt='Home image 5'className='responsiveHomeDisplayImages-image5'/>
            <img src={homepage_frame_2}  alt='Home image 6'className='responsiveHomeDisplayImages-image6'/>
            </div>
            
        </div>
    );
}


const HomeMainSection=()=>

(<div className='homeMainSection'>

<div className='homeMainSection__left'>
    <div className='getStartedBox'>

<p className='home-getStartedBox__title'>Discover and stream<br/>stunning Art on your TV</p>
<p className='home-getStartedBox__subtitle'>At PiXLMe, we believe in making beautiful art accessible to<br/>everyone. Discover, curate, and stream high-quality art<br/>collections to your TV and other display devices,<br/>transforming your space into a dynamic art gallery.</p>
<div  className='getStartedBox__button'> <p>Get Started</p> </div>
    </div>

  
    <div className='getStarted_images'>
<img src= {homepage_frame_1} alt="home image 1"  className='homeMainSection__image1'/> 
<img src={homepage_frame_2}  alt="Lhome image 2" className='homeMainSection__image2'/> 
    </div>
   
    <ResponsiveHomeDisplayImages/>
</div>
<div className='homeMainSection__right'>
<div  className='homeMainSection__subleft'><img src={homepage_frame_3}  alt="home image 3" className='homeMainSection__image3'/> 
<img src='https://pixlme.com/static/media/homepage_frame_4.129697d69828d8ea5f81.webp'  alt="home image 4" className='homeMainSection__image4'/> 
<img src={homepage_frame_5}  alt="home image 5" className='homeMainSection__image5'/> </div>
<div className='homeMainSection__subright'>
    <img src={homepage_frame_6}  alt="home image 6" className='homeMainSection__image6'/></div>

</div>

</div>);





export default HomeMainSection;